import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  busyRequestCount = 0;

  constructor(private spinnerService: NgxSpinnerService) { }

  busy() {
    this.busyRequestCount++;

    this.spinnerService.show(undefined, {
        type: 'line-scale',
        bdColor: 'rgba(253,253,253,0.8)',
        color: '#ec3654'
      });

   /*  setTimeout(() => {
        // spinner ends after 5 seconds 
       this.spinnerService.hide();        
      }, 3000); */
   
  }

   idle() {
   
     this.busyRequestCount--;
    if (this.busyRequestCount <= 0) {
      this.busyRequestCount = 0;
      setTimeout(() => {
        // spinner ends after 5 seconds 
       this.spinnerService.hide();        
      }, 2000);
    } 
  } 
}