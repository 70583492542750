import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, JsonpClientBackend } from '@angular/common/http';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { map, filter, tap, catchError } from 'rxjs/operators';
import {login_cmd} from './models/login-cmd';
import {user_logged} from './models/user-logged';
import {roles} from './models/roles';
import {list_permissions} from './models/list_permissions';

import {environment } from '../../../../environments/environment';

import {ApiAuthAndUserDataServicePath } from './api-auth-and-user-data-service-path'
/* 
import { LoginQuery } from './login-query';
import {UserLoggedResponse} from './user-logged-response';
import { ApiAuthAndUserDataServicePath } from './api-auth-user-data-service-path';
 */
/* import { ChangePasswordCommand } from './change-password-command';
import { ForgotPasswordCommand } from './forgot-password-command';
import { LockUserCommand } from './lock-user-command';

import {GetUserReponse} from './get-user-response';
import {UsersOfEntityReponse} from './users-of-entity-reponse';
import { RegisterUserCommand } from './register-user-command';
import { ResetUserCommand } from './reset-user-command';
import { UnlockUserCommand } from './unlock-user-command';
import { UpdateUserCommand } from './update-user-command';
import { UpdateUserPermissionsCommand } from './update-user-permissions-command';

 */
import {ApiConfiguration} from '../api-configuration';
import jwt_decode from "jwt-decode";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ApiAuthDataService {

  url : string="";
  zoom_user_logged_storage_key ='zoom_user_logged_storage_key';
  
  loggedUser: user_logged=new user_logged();
  
  private currentUserSubject= new ReplaySubject<user_logged>(1);
  currentUser$ = this.currentUserSubject.asObservable();

  constructor( public config: ApiConfiguration,public http: HttpClient, private router : Router){ }

  Login(login:login_cmd) : Observable<String> {

  this.url=environment.baseUrl_zoomUsersPermissions + ApiAuthAndUserDataServicePath.LoginPostPath;

  return this.http.post<any>(this.url, login).pipe(
                                                tap( tokens => {  console.log(JSON.stringify(tokens)); }),
                                                map( tokens =>  this.store_logged_user_and_redirect(tokens)),
                                                catchError(this.handleError)
                                                );
  }

  private store_logged_user_and_redirect(tokens:any){

    this.store_logged_user(tokens);


    if(this.loggedUser.first_login==true)
    return '/home/login'; // maybe we should send a toast askin for account activation also

    if(this.loggedUser.force_password_reset==true)
    return '/home/external-change-password';

    if(this.loggedUser.role===roles.Admin_User_Role)
    return '/admin/dashboard';

    else   if(this.loggedUser.role===roles.Zoom_User_Role)
    return '/zoom/dashboard';

    else   if(this.loggedUser.role===roles.Company_User_Role)
    return '/company/dashboard';

    else  return '/home/login';
  }

  private store_logged_user(tokens){
   
    console.log(this.loggedUser);
    this.loggedUser.id_token = tokens.id_token;
    this.loggedUser.access_token = tokens.id_token;
    this.loggedUser.refresh_token = tokens.id_token;
    this.loggedUser.expire_in = tokens.id_token;

    let decoded_id_token = jwt_decode(tokens.id_token);

    this.loggedUser.user_name = decoded_id_token['cognito:username'];
    this.loggedUser.first_name = decoded_id_token['given_name'];
    this.loggedUser.permissions = decoded_id_token['custom:permissions'].split('-').map(i=>Number(i));;
    this.loggedUser.role = decoded_id_token['profile'];

    this.loggedUser.force_password_reset = decoded_id_token['custom:force_password_reset'];

    this.loggedUser.first_login = decoded_id_token['custom:first_login'];


    console.log("decoded user " +this.loggedUser);
  
    localStorage.setItem(this.zoom_user_logged_storage_key, JSON.stringify(this.loggedUser));

    this.currentUserSubject.next(this.loggedUser);
  }

  private get_refresh_token()
  {
    return ( JSON.parse(localStorage.getItem(this.zoom_user_logged_storage_key)) as user_logged).refresh_token;
  }

  CheckAccessToken()
  {
    return !!localStorage.getItem(this.zoom_user_logged_storage_key);
  }
 
  GetAccessToken()
  {
    return ( JSON.parse(localStorage.getItem(this.zoom_user_logged_storage_key)) as user_logged).access_token;
  }
 
  Logout() {
    localStorage.removeItem(this.zoom_user_logged_storage_key);
    this.currentUserSubject.next(null);
    this.router.navigateByUrl('/home/login');
  }

  RefreshToken() {
    this.url=environment.baseUrl_zoomUsersPermissions + ApiAuthAndUserDataServicePath.RefreshTokenPostPath;

    return this.http.post<any>(this.url, {refresh_token : this.get_refresh_token()  })
                                               .pipe(
                                                tap( tokens =>  this.store_logged_user(tokens)),                                               
                                                catchError(this.handleError)
                                                );
  }
  
  UserHasPermission(permission_id : string){
    
    if (permission_id=="1" || permission_id=="2")
    return true;
    else
    return false;
  }
/* Register(register :RegisterUserCommand) {
  this.url=this.config.rootUrl + ApiUserService.RegisterPostPath;
  return this.http.post(this.url, register);
 }

ChangePassword(chgpwd:ChangePasswordCommand) { 
  this.url=this.config.rootUrl + ApiUserService.ChangePasswordPostPath;
  return this.http.post(this.url, chgpwd);
}

Lock(lock:LockUserCommand) { 
  this.url=this.config.rootUrl + ApiUserService.LockPostPath;
  return this.http.post(this.url, lock);
}

Unlock(unlock:UnlockUserCommand) { 
  this.url=this.config.rootUrl + ApiUserService.UnlockPostPath;
  return this.http.post(this.url, unlock);
}

Reset(reset:ResetUserCommand) { 
  this.url=this.config.rootUrl + ApiUserService.ResetPostPath;
  return this.http.post(this.url, reset);
}

UpdateUser(upuser:UpdateUserCommand) { 
  this.url=this.config.rootUrl + ApiUserService.UpdateUserPostPath;
  return this.http.post(this.url, upuser);
}

UpdateUserPermissions(upuserpermission:UpdateUserPermissionsCommand) { 
  this.url=this.config.rootUrl + ApiUserService.UpdateUserPermissionsPostPath;
  return this.http.post(this.url, upuserpermission);
}

GetUser(username:string) { 
  this.url=this.config.rootUrl + ApiUserService.GetUserGetPath;
  return this.http.get<GetUserReponse>(this.url+'/?user_name='+username);
}

GetUsersForAnEntity(entity_id:string) { 
  this.url=this.config.rootUrl + ApiUserService.GetUsersForAnEntityGetPath;
  return this.http.get<UsersOfEntityReponse[]>(this.url+'/?entity_id='+entity_id);
}

ForgotPassword(frgotpwd:ForgotPasswordCommand) { 
  this.url=this.config.rootUrl + ApiUserService.ForgotPasswordPostPath;
  return this.http.post(this.url, frgotpwd);
}
 */

private handleError(err: any): Observable<never> {
  // in a real world app, we may send the server to some remote logging infrastructure
  // instead of just logging it to the console
  let errorMessage: string;
  if (err.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    errorMessage = `An error occurred: ${err.error.message}`;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
  }
  console.error(err);
  return throwError(errorMessage);
  
  }

}
