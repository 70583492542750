export class ApiAuthAndUserDataServicePath
{
  
    static readonly LoginPostPath = '/Api/User/Login';
    static readonly RefreshTokenPostPath = '/Api/User/RefreshToken';

    static readonly RegisterPostPath = '/api/User/Register';
    static readonly ChangePasswordPostPath = '/api/User/ChangePassword';
    static readonly LockPostPath = '/api/User/Lock';
    static readonly UnlockPostPath = '/api/User/Unlock';
    static readonly ResetPostPath = '/api/User/Reset';
    static readonly UpdateUserPostPath = '/api/User/UpdateUser';
    static readonly UpdateUserPermissionsPostPath = '/api/User/UpdateUserPermissions';
    static readonly GetUserGetPath = '/api/User/GetUser';
    static readonly GetUsersForAnEntityGetPath = '/api/User/GetUsersForAnEntity';
    static readonly ForgotPasswordPostPath = '/api/User/ForgotPassword';
}