import { Injectable } from '@angular/core';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApiReferenceDataServicePath} from '../api-reference-data-service/api-reference-data-path';
import {sector} from '../api-reference-data-service/sector';
import { towns_quaters} from '../api-reference-data-service/towns_quaters';
import { from, of,combineLatest, throwError } from 'rxjs';
import { keys_words } from './keys_words';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { CreateCity_Command, Createquater_Command, Create_Street_Command } from './CreateCity_Command';
import { countries } from './countries';
import { forme_juridique } from './forme_juridique';
import { city, city_quater_search_table, quater, street} from './city';
import { UpdateCity_Command, UpdateQuater_Command, Update_Streets_Command } from './UpdateCity_Command';
import { Create_Rubrique_Command } from './Create_Rubrique_Command';
import { city_view, quater_view, street_view } from './city_view';
import { rubrique_view } from './rubrique_view';
import { rubrique } from './rubrique';
import { sous_rubrique } from './sous_rubrique';
import { Update_Rubrique_Command } from './Update_Rubrique_Command';
import { Create_SousRubrique_Command } from './Create_SousRubrique_Command';
import { Update_SousRubrique_Command } from './Update_SousRubrique_Command';
import { sous_rubrique_view } from './sous_rubrique_view';
import { country_city_drop_cascade } from './country_city_drop_cascade';
import { rubrique_drop_cascade, sector_rubrique_sousrubrique_drop_cascade,sous_rubrique_drop_cascade } from './sector_rubrique_sousrubrique_drop_cascade';



@Injectable({
  providedIn: 'root'
})
export class ApiReferenceDataService {

  url : string="";
  constructor(public http: HttpClient) 
  { }

  AllCountries$ = this.http.get<countries[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllCountries).pipe(shareReplay());

  FormJusritique$ = this.http.get<forme_juridique[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetFormesJuridique).pipe(shareReplay());

  AllCities$ = this.http.get<city[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllCities).pipe(
                                                                                                         //   shareReplay(),
                                                                                                            tap(response => {
                                                                                                                console.log(JSON.stringify(response));
                                                                                                            }),
                                                                                                            catchError(this.handleError)
                                                                                                        ); 
  AllQuaters$ = this.http.get<quater[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllQuaters).pipe(
                                                                                                         // shareReplay(),
                                                                                                          tap(response => {
                                                                                                              console.log(JSON.stringify(response));
                                                                                                          }),
                                                                                                          catchError(this.handleError)
                                                                                                      );  

   AllStreets$ = this.http.get<street[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllStreetsOrStreetByCity+ "/?city_id=0").pipe(
                                                                                                        // shareReplay(),
                                                                                                         tap(response => {
                                                                                                             console.log(JSON.stringify(response));
                                                                                                         }),
                                                                                                         catchError(this.handleError)
                                                                                                     );                                                                                                        
  AllCitiesQuatersForSearchSuggestion$ = this.http.get<city_quater_search_table[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllCitiesQuaters).pipe(
                                                                                                          shareReplay(),
                                                                                                          tap(response => {
                                                                                                              console.log(JSON.stringify(response));
                                                                                                          }),
                                                                                                          catchError(this.handleError)
                                                                                                      ); 

  get_all_cities_quaters_by_country(query:string, country_id: string){

     return   this.AllCitiesQuatersForSearchSuggestion$.pipe(
    
          map(cities => {

            var c : city_quater_search_table[]=[];

            cities.forEach(element => {

               var city_name : string = "";

               city_name = element.city_quater_name;

              if(element.country_id===country_id && city_name.toLowerCase().includes(query.toLowerCase()))
              c.push(element);
           });

            return c;
          }),
    
          tap(response => {
              console.log(JSON.stringify(response));
          }),
          catchError(this.handleError)
      );  
     
  }  
  get_all_cities_by_country(country_id: string){

    return   this.AllCities$.pipe(
   
         map(cities => {

           var c : city[]=[];

           cities.forEach(element => {
             
             if(element.country_id===country_id)
             c.push(element);
          });

           return c;
         }),
   
         tap(response => {
             console.log(JSON.stringify(response));
         }),
         catchError(this.handleError)
     );  
    
 }  
  AllSectors$ = this.http.get<sector[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllSectors).pipe(shareReplay());//.pipe(shareReplay());
 
  AllRubriques$ = this.http.get<rubrique[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllRubriques).pipe(shareReplay());

  AllSousRubriques$ = this.http.get<sous_rubrique[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllSousRubriques).pipe(shareReplay());

  CompanyStatus$ = this.http.get<string[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetCompanyValidationStatus);
  
  companyPlusInfoStatus$ = of(["Valide","Invalide"]);

  Countries_And_Cities$ = combineLatest( [this.AllCountries$,this.AllCities$])

                          .pipe(map(([AllCountries,AllCities])=>{
                           
                            var result:city_view[]=[];

                            AllCities.map(city=>{

                              result.push({ id:city.id,
                                            country_id : city.country_id,
                                            city_name : city.city_name,
                                            country_name : AllCountries.find(country => country.id == city.country_id).name_fr});
                            });
                 
                           // console.log(JSON.stringify(result));
                            return result;
                          }),
                          tap(data => { 
                                    // console.log(JSON.stringify(data));
                                   }));
 Cities_And_Streets$ = combineLatest( [this.AllCities$,this.AllStreets$])

                          .pipe(map(([AllCities,AllStreets])=>{
                           
                            var result:street_view[]=[];

                            AllStreets.map(street=>{

                              result.push({ id:street.id,
                                            city_id : street.city_id,
                                            street_name :street.street_name,
                                            street_number : street.street_number,
                                            city_name : AllCities.find(city => city.id == street.city_id).city_name});
                            });
                 
                           // console.log(JSON.stringify(result));
                            return result;
                          }),
                          tap(data => { 
                                    // console.log(JSON.stringify(data));
                                   }));
 Cities_And_Quaters$ = combineLatest( [this.AllCities$,this.AllQuaters$])

                                   .pipe(map(([AllCities,AllQuaters])=>{
                                    
                                     var result:quater_view[]=[];
         
                                     AllQuaters.map(quater=>{
         
                                       result.push({ id:quater.id,
                                                     city_id : quater.city_id,
                                                     quater_name :quater.quater_name,
                                                     city_name : AllCities.find(city => city.id == quater.city_id).city_name});
                                     });
                          
                                    // console.log(JSON.stringify(result));
                                     return result;
                                   }),
                                   tap(data => { 
                                             // console.log(JSON.stringify(data));
                                            }));                                                                                                       
  Sectors_and_Rubriques$ = combineLatest( [this.AllSectors$ ,this. AllRubriques$])

                          .pipe(map(([AllSectors,AllRubriques])=>{
                         //   console.log(JSON.stringify(AllSectors));
                            console.log(JSON.stringify(AllRubriques));

                            var result:rubrique_view[]=[];

                            AllRubriques.map(rubrique=>{

                              result.push({id:rubrique.id,
                                           rubrique_name_fr : rubrique.rubrique_name_fr,
                                           rubrique_name_en : rubrique.rubrique_name_en,
                                           sector_id :rubrique.sector_id,
                                           sector_name : AllSectors.find(sector=>sector.id==rubrique.sector_id).name_fr});
                            });

                            //console.log(JSON.stringify(result));
                            return result;
                          }),
                          tap(data => { 
                                    console.log(JSON.stringify(data)); }));
  
  Sectors_Rubriques_and_SousRubriques$ = combineLatest( [this.AllSectors$ ,this. AllRubriques$, this.AllSousRubriques$])

                                        .pipe(map(([AllSectors,AllRubriques,AllSousRubriques])=>{

                                      //   console.log(JSON.stringify(AllSectors));
                                      //    console.log(JSON.stringify(AllRubriques));

                                          var result:sous_rubrique_view[]=[];

                                          AllRubriques.map(rubrique=>{

                                                                        var rub : sous_rubrique_view = {};

                                                                        rub.rubrique_id = rubrique.id;
                                                                        rub.rubrique_name_en = rubrique.rubrique_name_en;
                                                                        rub.rubrique_name_fr = rubrique.rubrique_name_fr;
                                                                        rub.sector_name = AllSectors.find(sector=>sector.id==rubrique.sector_id).name_fr;
                                                                        rub.sector_id =  AllSectors.find(sector=>sector.id==rubrique.sector_id).id;
                                                                        
                                                                        var sub_rub = AllSousRubriques.filter(ss=>ss.rubrique_id==rubrique.id) ;
                                                                       
                                                                        if(sub_rub.length>0)
                                                                            sub_rub.forEach(sousrub => {
                                                                                                              console.log('sub_rub---------------' +JSON.stringify(rub) + 'sub_rub---------------');

                                                                                                              result.push({
                                                                                                                rubrique_id : rub.rubrique_id,
                                                                                                                rubrique_name_en : rub.rubrique_name_en,
                                                                                                                rubrique_name_fr : rub.rubrique_name_fr,
                                                                                                                sector_name : rub.sector_name,
                                                                                                                sous_rubrique_id : sousrub.id,
                                                                                                                sous_rubrique_name_en : sousrub.sous_rubrique_name_en,
                                                                                                                sous_rubrique_name_fr : sousrub.sous_rubrique_name_fr                                                                                                                
                                                                                                              });
                                                                                                        });                                                                        
                                                                        else
                                                                            result.push(rub);                                                                      
                                                                    })
                                          console.log(JSON.stringify(result));
                                          return result;
                                                                  }),
                                        tap(data => { 
                                                       console.log(JSON.stringify(data)); 
                                                    }));
  
  
  Filter_By_Sectors$ = this.AllSectors$.pipe(map(sectors => sectors.map(s=>s.name_fr)) );

  Filter_By_Rubriques$ = this.AllRubriques$.pipe(map(rub => rub.map(s=>s.rubrique_name_fr)));
//.pipe(map(sectors=> sectors.map(item => {return {value: item.name_fr,label:item.name_fr}})),
                                                    //      tap(data => console.log('table_sector_filter  ------------------:' + JSON.stringify(data))));
 
  Countries_And_Cities_For_DropCascade$ = combineLatest( [this.AllCountries$,this.AllCities$])

                                                    .pipe(map(([AllCountries,AllCities])=>{
                                                     
                                                      var result:country_city_drop_cascade[]=[];
                          
                                                      AllCountries.map(country=>{
                                                         var c =  AllCities.filter(city => city.country_id == country.id)?.map(c=>{return {city_id: c.id, city_name : c.city_name}});
                                                        result.push({ 
                                                                      country_id : country.id,
                                                                      country_name : country.name_fr,
                                                                      cities : c });
                                                      });
                                           
                                                     // console.log(JSON.stringify(result));
                                                      return result;
                                                    }),
                                                    tap(data => { 
                                                              // console.log(JSON.stringify(data));
                                                             })); 

  Sectors_Rubriques_and_SousRubriques_For_DropCascade$ = combineLatest( [this.AllSectors$ ,this. AllRubriques$, this.AllSousRubriques$])

                                                      .pipe(map(([AllSectors,AllRubriques,AllSousRubriques])=>{
                                                        
                                                        var result = [];
                                                        
                                                        AllSectors.map(sector=>{
                                                          var rubs = AllRubriques.filter(rubriq=> rubriq.sector_id==sector.id)?.map(r=>{return{rubrique_id:r.id,
                                                                                                                                               rubrique_name : r.rubrique_name_fr,
                                                                                                                                               name:r.rubrique_name_fr} });
                                                           if(rubs.length==0)
                                                           result.push({sector_id:sector.id, sector_name : sector.name_fr, name:sector.name_fr}) ;
                                                           else{

                                                              var rubs_and_subrub_array: any = [];
                                                              rubs.forEach(r=>{
                                                                var srub = AllSousRubriques.filter(ss=>ss.rubrique_id==r.rubrique_id)?.map(sss=>{return { sous_rubrique_id:sss.id,
                                                                                                                                                                  sous_rubrique_name : sss.sous_rubrique_name_fr,
                                                                                                                                                                  name : sss.sous_rubrique_name_fr,
                                                                                                                                                                  rubrique_id : sss.rubrique_id
                                                                                                                                                        }}); 
                                                                
                                                                    if(srub.length!=0)                                                                     
                                                                     rubs_and_subrub_array.push({name : r.name,rubrique_name: r.rubrique_name,rubrique_id :r.rubrique_id, sous_rubriques :srub});  
                                                                    else 
                                                                     rubs_and_subrub_array.push({name : r.name,rubrique_name: r.rubrique_name,rubrique_id :r.rubrique_id });                                                                                                                                                   
                                                              });                                                              
                                                          
                                                              result.push({sector_id:sector.id, sector_name : sector.name_fr, name:sector.name_fr, rubriques : rubs_and_subrub_array});
                                                          }    
                                                        });

                                                     //   console.log(JSON.stringify(result));

                                                        return result;
                                                      }));   

  create_city(city : CreateCity_Command){
    
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.CreateCity;
    return this.http.post(this.url, city);
  }
  update_city(city : UpdateCity_Command){
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.UpdateCity;
    return this.http.post(this.url, city);
  }

  create_quater(quater : Createquater_Command){
    
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.CreateQuater;
    return this.http.post(this.url, quater);
  }

  update_quater(quater : UpdateQuater_Command){
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.UpdateQuater;
    return this.http.post(this.url, quater);
  }

  run_update_cities_quaters_search_table(){
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.RunUpdateCitiesQuatersSearchTable;
    return this.http.post(this.url, {});
  }


  create_rubrique(rubrique : Create_Rubrique_Command){   
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.CreateRubrique;
    return this.http.post(this.url, rubrique);
  }

  update_rubrique(rubrique : Update_Rubrique_Command){  
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.UpdateRubrique;
    return this.http.post(this.url, rubrique);
  }

  create_sous_rubrique(sous_rubrique : Create_SousRubrique_Command){   
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.CreateSousRubrique;
    return this.http.post(this.url, sous_rubrique);
  }

  update_sous_rubrique(sous_rubrique : Update_SousRubrique_Command){   
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.UpdateSousRubrique;
    return this.http.post(this.url, sous_rubrique);
  }

  create_street(street : Create_Street_Command){   
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.CreateStreet;
    return this.http.post(this.url, street);
  }

  update_street(street : Update_Streets_Command){   
    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.UpdateStreet;
    return this.http.post(this.url, street);
  }

  get_all_streets_or_streets_by_city(city_id : string){ 
    // if city_id = 0 then all streets to be return

    this.url = environment.baseUrl_zoomApi + ApiReferenceDataServicePath.GetAllStreetsOrStreetByCity;
    return this.http.get(this.url+"/?city_id="+city_id);
  }
  sectors : any[] =[
                        {id:'1',icon : '1administration2.png', sector_name_fr : 'ADMINISTRATION'},
                        {id:'2',icon : 'agriculture.png', sector_name_fr : 'AGRICULTURE'},
                        {id:'3',icon : '1commerce.png', sector_name_fr : 'COMMERCE'},
                        {id:'4',icon : '1communication.png', sector_name_fr : 'COMMUNICATION'},
                        {id:'5',icon : '1diplomatie2.png', sector_name_fr : 'COOPERATION-DIPLOMATIE'},
                        {id:'6',icon : '1enseignement.png', sector_name_fr : 'ENSEIGNEMENT'},
                        {id:'7',icon : '1finance.png', sector_name_fr : 'FINANCE-GESTION'},
                        {id:'8',icon : 'hebergement.png', sector_name_fr : 'HEBERGEMENT'},
                        {id:'9',icon : '1industrie2.png', sector_name_fr : 'INDUSTRIES'},
                        {id:'10',icon : '1professionel3.png', sector_name_fr : 'PROFESSION LIBERALES'},
                        {id:'11',icon : '1sante.png', sector_name_fr : 'SANTE'},
                        {id:'12',icon : '1service.png', sector_name_fr : 'SERVICES'},
                        {id:'13',icon : '1social.png', sector_name_fr : 'SOCIAL'},
                        {id:'14',icon : '1sport.png', sector_name_fr : 'SPORT-LOISIR'},
                        {id:'15',icon : '1transport.png', sector_name_fr : 'TRANSPORT'}
                   ];
 
 
  country_towns_quaters : towns_quaters[] =[
                          {id:'1', town: 'Douala', quater : 'Akwa'},
                          {id:'2',town : 'Douala', quater : 'Port'},
                          {id:'3',town : 'Douala', quater : 'Bali'},
                          {id:'4',town : 'Douala', quater : 'Deido'},
                          {id:'5',town : 'Douala', quater : 'Bonapriso'},
                          {id:'6',town : 'Douala', quater : 'New Bell'},
                          {id:'7',town : 'Douala', quater : 'Bonanjo'},
                          {id:'8',town : 'Douala', quater : ''},
                          {id:'9',town : 'Yaounde', quater : ''},
                          {id:'10',town : 'Yaounde', quater : 'Simbog'},
                          {id:'11',town : 'Yaounde', quater : 'Nsam'},
                          {id:'12',town : 'Yaounde', quater : 'SERVICES'},
                          {id:'13',town : 'Yaounde', quater : 'Essos'},
                          {id:'14',town : 'Yaounde', quater : 'Poste Centrale'},
                          {id:'15',town : 'Yaounde', quater : 'Edzoa'}];
 keys_words : keys_words[] =[
                            {id:'1', key_words_fr: 'TRANSPORT AGENCES DE VOYAGES-TOURISME', key_words_en : 'Akwa'},
                            {id:'2',key_words_fr : 'TRANSPORT FRET', key_words_en : 'Port'},
                            {id:'3',key_words_fr : 'TRANSPORT AERIEN', key_words_en : 'Bali'},
                            {id:'4',key_words_fr : 'TRANSPORT FERROVIAIRE', key_words_en : 'Deido'},
                            {id:'5',key_words_fr : 'TRANSPORT MARITIME-MANUTENTION-ACCONAGE', key_words_en : 'Bonapriso'},
                            {id:'6',key_words_fr : 'TRANSPORT ROUTIER DE MARCHANDISES', key_words_en : 'New Bell'},
                            {id:'7',key_words_fr : 'TRANSPORT INTERURBAINS', key_words_en : 'Bonanjo'},
                            {id:'8',key_words_fr : 'TRANSPORT URBAINS', key_words_en : ''}];
                          
    
  get_sectors() { 
//    this.url=this.config.rootUrl + ApiReferenceDataServicePath.GetSectorPath;
    return of(this.sectors);
    //return this.http.get<sector[]>(this.url);
  }

  get_rubriques() { 
  return   this.http.get<rubrique[]>(environment.baseUrl_zoomApi+ApiReferenceDataServicePath.GetAllRubriques);
  }
 
  get_towns_quaters(country_code : string){
   // this.url=this.config.rootUrl + ApiReferenceDataServicePath.GetTownsQuatersForCountryPath;
    return of(this.country_towns_quaters);
    //return this.http.get<towns_quaters[]>(this.url);
  }

  get_key_words(search_term : string){
 //   this.url=this.config.rootUrl + ApiReferenceDataServicePath.GetKeywordsPath;
    return of(this.keys_words);
    
  }

 

  

  
              
   
  Sectors$ = of([
                  {
                   id : "-1",
                   name :"sector -1",
                   type : 'SECTOR'
                  },
                  {
                   id : "1",
                   name :"sector 1",
                   type : 'SECTOR',
                   rubriques : [
                                {
                                  id: "r1", 
                                  name : "rubrique 1",
                                  type : 'RUBRIQUE',
                                  sousRubriques : [ {
                                                      id:"s1",
                                                      name :"sousRubriques 1",
                                                      type : 'SOUSRUBRIQUE'
                                                    },
                                                    {
                                                      id:"s2",
                                                      name :"sousRubriques 2",
                                                      type : 'SOUSRUBRIQUE'
                                                    },
                                                    {
                                                      id:"s3",
                                                      name :"sousRubriques 3",
                                                      type : 'SOUSRUBRIQUE'
                                                    }
                                                  ]
                                },
                                {
                                  id: "r2", 
                                  name : "rubrique 2",
                                  type : 'RUBRIQUE'                             
                                },
                                {
                                  id: "r3", 
                                  name : "rubrique 3",
                                  type : 'RUBRIQUE',
                                  sousRubriques : [ {
                                                      id:"s4",
                                                      name :"sousRubriques 4",
                                                      type : 'SOUSRUBRIQUE'
                                                    },
                                                    {
                                                      id:"s5",
                                                      name :"sousRubriques 5",
                                                      type : 'SOUSRUBRIQUE'
                                                    },
                                                    {
                                                      id:"s6",
                                                      name :"sousRubriques 6",
                                                      type : 'SOUSRUBRIQUE'
                                                    }
                                                  ]
                                },
                              ]  
                  },

                  {
                    id : "2",
                    name :"sector 2",
                    type : 'SECTOR',
                    rubriques : [
                                 {
                                   id: "r4", 
                                   name : "rubrique 4",
                                   type : 'RUBRIQUE',
                                   sousRubriques : [ {
                                                       id:"s7",
                                                       name :"sousRubriques 7",
                                                       type : 'SOUSRUBRIQUE'
                                                     },
                                                     {
                                                       id:"s8",
                                                       name :"sousRubriques 8",
                                                       type : 'SOUSRUBRIQUE'
                                                     },
                                                     {
                                                       id:"s9",
                                                       name :"sousRubriques 9",
                                                       type : 'SOUSRUBRIQUE'
                                                     }
                                                   ]
                                 },
                                 {
                                   id: "r5", 
                                   name : "rubrique 5",
                                   type : 'RUBRIQUE',                             
                                 },
                                 {
                                   id: "r6", 
                                   name : "rubrique 6",
                                   type : 'RUBRIQUE',
                                   sousRubriques : [ {
                                                       id:"s10",
                                                       name :"sousRubriques 10",
                                                       type : 'SOUSRUBRIQUE'
                                                     },
                                                     {
                                                       id:"s11",
                                                       name :"sousRubriques 11",
                                                       type : 'SOUSRUBRIQUE',
                                                     },
                                                     {
                                                       id:"s12",
                                                       name :"sousRubriques 12",
                                                       type : 'SOUSRUBRIQUE'
                                                     }
                                                   ]
                                 },
                               ]  
                   },
 
               ]);

Sectors_And_Rubriques$ =this.Sectors$.pipe(map(sectors =>  {
                var result = [];
                  sectors.forEach((sector)=>{
                    sector.rubriques?.forEach((rubrique)=>{
                    result.push({sector :sector.name,rubrique_id: rubrique.id, rubrique: rubrique.name, rubrique_status :"Live"})
                  });
                });
                return result;
              }));

 Sectors_And_Rubriques_SousRubriques$ =this.Sectors$.pipe(map(sectors =>  {
                var result = [];
                  sectors.forEach((sector)=>{
                    sector.rubriques?.forEach((rubrique)=>{
                      rubrique.sousRubriques?.forEach((sous_rubrique)=>{

                        result.push({sector :sector.name, rubrique: rubrique.name,sous_rubrique_id: sous_rubrique.id,sous_rubrique : sous_rubrique.name, sous_rubrique_status :"Live"})

                      });
                  });
                });
                return result;
              }));


private handleError(err: any) {
                // in a real world app, we may send the server to some remote logging infrastructure
                // instead of just logging it to the console
                let errorMessage: string;
                if (err.error instanceof ErrorEvent) {
                    // A client-side or network error occurred. Handle it accordingly.
                    errorMessage = `An error occurred: ${err.error.message}`;
                } else {
                    // The backend returned an unsuccessful response code.
                    // The response body may contain clues as to what went wrong,
                    errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
                }
                console.error(err);
              
            
                return throwError(errorMessage);
     }
  }

  