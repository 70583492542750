<div class="coming-soon-area">
  <div class="d-table">
      <div class="d-table-cell">
          <div class="container">
              <div class="coming-soon-content">
                 <!--  <img src="assets/img/logo/zoominfo-logo.svg" alt="Images"> -->

                  <h1>Coming Soon</h1>
                  <p>Lancement Officiel de la platforme ZOOM-INFO.NET , Veuillez entrer votre email pour etre notifier , suivez nous sur nos plateforme reseaux-socio </p>
                  <div id="timer">
                      <div id="days"></div>
                      <div id="hours"></div>
                      <div id="minutes"></div>
                      <div id="seconds"></div>
                  </div>
                  <form class="newsletter-form">
                      <input type="email" class="input-newsletter" placeholder="Entrer votre email pour etre notifier" name="EMAIL" required autocomplete="off">
                      <button type="submit" class="default-btn">Notifier Moi</button>
                  </form>
                  <ul class="header-content-right">
                      <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                      <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                      <li><a href="#" target="_blank"><i class="bx bxl-pinterest-alt"></i></a></li>
                      <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>
