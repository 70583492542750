import { NgModule } from '@angular/core';
import { Routes, RouterModule,ExtraOptions } from '@angular/router';


/* import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component'; */
import { ComingSoonComponent } from './modules/public-pages/coming-soon/coming-soon.component';

const routes: Routes = [
   // {path: '', component: ComingSoonComponent},
   // {path: '', component: HomeOneComponent},
    { path: '', redirectTo: 'home', pathMatch: 'full'},
    { path: 'home', loadChildren: () => import('./modules/public-pages/public-pages.module').then(m => m.PublicPagesModule) },
    { path: 'company', loadChildren: () => import('./modules/company-pages/company-pages.module').then(m => m.CompanyPagesModule) },
    { path: 'zoom', loadChildren: () => import('./modules/zoom-pages/zoom-pages.module').then(m => m.ZoomPagesModule) },
    { path: 'admin', loadChildren: () => import('./modules/Admin-pages/admin-pages.module').then(m => m.AdminPagesModule) },

   // { path: 'admin', loadChildren: () => import('./modules/admin/admin-module').then(m => m.AdminModule) },
   // { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
 /*    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'category', component: CategoryComponent},
    {path: 'shop', component: ShopComponent},
    {path: 'cart', component: CartComponent},
    {path: 'checkout', component: CheckoutComponent},
    {path: 'products-details', component: ProductsDetailsComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'login-register', component: LoginRegisterComponent},
    {path: 'terms-condition', component: TermsConditionComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'listing', component: ListingComponent},
    {path: 'listing-details', component: ListingDetailsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: '**', component: ErrorComponent},*/
    {path: '**',  redirectTo: 'coming-soon', pathMatch: 'full'},
];


  // to enable use of fragment to jumbe within the same page but different section ==>https://www.geekstrick.com/fragment-url-in-angular-8/
  //https://stackblitz.com/edit/angular-nested-routing-with-modules-with-bootstrap?embed=1&file=src/index.html
  //https://www.freakyjolly.com/angular-nested-routing-with-multiple-routeroutlet-using-loadchildren-having-own-router-modules-example-application/#.X5_g7VhKiUk

  const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',

    anchorScrolling: 'enabled',
    scrollOffset: [0, 64],//// cool option, or ideal option when you have a fixed header on top.
  //  enableTracing: true // to be remove for production, it just ro see routing event in console
  };

@NgModule({
    imports: [RouterModule.forRoot(routes,routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
