import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ApiConfiguration} from '../api-configuration';
import {ApiSearchDataServicePath} from '../api-search-data-service/api-search-data-path';
import { of, throwError } from 'rxjs';
import { companySearchResult } from './models/company-search-result';
import { Get_Search_Keywords_Query } from './models/Get_Search_Keywords_Query';
import { catchError, tap } from 'rxjs/operators';
import { search_sugestion_key_words } from './models/search_sugestion_key_words';
import { environment } from 'src/environments/environment';
import { Search_Companies_Query } from './models/Search_Companies_Query';
import { company } from '../api-zoom-data-check-service/company';
import { Get_Company_Details_Query } from './models/Get_Company_Details_Query';
import { Global_Search_Query } from './models/Global_Search_Query';
import { Search_By_City_Or_Letter_Query } from './models/Search_By_City_Or_Letter_Query';


@Injectable({
  providedIn: 'root'
})
export class ApiSearchDataService {

  url : string="";

/*   companies : companySearchResult[] =[
                        {id:'-1',logo : 'assets/img/logo/Activa.png', company_name : 'Activa Assurances',category:'Assurances',
                        prestations_services:'Assurance Sante - Vie - Voyage - Auto - Epargne',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@activa-cameroun.com',
                        website :'activa-cameroun.com',sim_zoom:'+237 652021280',plus_info:true,is_collapsed:true, end_plus_info_subscription :new Date("2020-02-16")},
                       
                        {id:'-2',logo : 'assets/img/logo/Logo CMA CGM .png', company_name : 'CMA CGM Cameoun',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@cma-cgm.com',
                        website :'www.cma-cgm.com',sim_zoom:'+237 652021280',plus_info:true,is_collapsed:true},

                        {id:'1',logo : 'assets/img/logo/Logo ACC.png', company_name : 'Aqua C G Cameoun',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:true,is_collapsed:true},

                        {id:'1',logo : 'assets/img/logo/Logo BleuMarine.png', company_name : 'Blue Marine',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit ',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},
                     
                        {id:'8',logo : 'assets/img/logo/Logo COMTRASECAM .png', company_name : 'COMTRASECAM',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:true,is_collapsed:true},

                        {id:'9',logo : 'assets/img/logo/Logo cygogne logistique .png', company_name : 'Cygogne Logistique',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'10',logo : 'assets/img/logo/Logo DHL.png', company_name : 'DHL Global Forwarding',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'6',logo : 'assets/img/logo/Logo CAREFOU LOGISTIQUE .png', company_name : 'CAREFOU LOGISTIQUE',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit ',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},
                      
                        {id:'11',logo : 'assets/img/logo/Logo EAGLE CAMEROUN .png', company_name : 'EAGLE CAMEROUN ',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'12',logo : 'assets/img/logo/Logo ESA.png', company_name : 'Express Service Approvisionnement',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},
                        
                    
                        {id:'13',logo : 'assets/img/logo/Logo FIFTY-FIFTY .png', company_name : 'FIFTY-FIFTY SHIPPING',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'14',logo : 'assets/img/logo/Logo GLOBAL SERVICES .png', company_name : 'Global Services',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'15',logo : 'assets/img/logo/Logo La CENTRALE .png', company_name : 'La Centrale',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'16',logo : 'assets/img/logo/Logo LOGIS .png', company_name : 'LOGIS',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'17',logo : 'assets/img/logo/Logo MOVIS .png', company_name : 'MOVIS',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'18',logo : 'assets/img/logo/Logo NILEDUTCH.png', company_name : 'NILEDUTCH',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'11',logo : 'assets/img/logo/Logo SOCOMAR .png', company_name : 'SOCOMAR',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'11',logo : 'assets/img/logo/Logo SOTAM .png', company_name : 'SOTAM',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'11',logo : 'assets/img/logo/Logo APM.png', company_name : 'APM',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},
                       
                        {id:'11',logo : 'assets/img/logo/Logo TRANS-INTER .png', company_name : 'TRANS-INTER',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},
                       
                        {id:'11',logo : 'assets/img/logo/Logo TRANSIMEX .png', company_name : 'TRANSIMEX',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'11',logo : 'assets/img/logo/Logo TRANSTAG .png', company_name : 'TRANSTAG',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit - Entrepot',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},

                        {id:'1',logo : 'assets/img/logo/Logo BleuMarine.png', company_name : 'Blue Marine',category:'Transit',
                        prestations_services:'Transport- Entrepot - Logistique - Transit ',
                        address:'36, Rue Sylvanie,Akwa B.P 4462 Douala, Cameroun',email : 'direction@zoom-info.com',
                        website :'www.zoom-info.com',sim_zoom:'+237 652021280',plus_info:false,is_collapsed:true},
                       
                        ]; */
         
         
  constructor( public http: HttpClient) 
  { }
  
  //Companies$ = of(this.companies);

  search(search : Search_Companies_Query) { 

    this.url = environment.baseUrl_zoomApi + ApiSearchDataServicePath.SearchCompaniesPath;

  return  this.http.get<companySearchResult[]>(this.url, {params : {... search}})
             .pipe(
                 tap(response => {
                     //console.log(JSON.stringify(response));
                 }),
                 catchError(this.handleError)
             ); 
    
    //return of(this.companies);
  }

  globalsearch(query :Global_Search_Query ){

    this.url = environment.baseUrl_zoomApi + ApiSearchDataServicePath.GlobalSearchPath;
    return  this.http.get<companySearchResult[]>(this.url, {params : {... query}})
               .pipe(
                   tap(response => {
                       console.log(JSON.stringify(response));
                   }),
                   catchError(this.handleError)
               ); 
      
  }
  
  search_by_city_or_letter(query :Search_By_City_Or_Letter_Query ){

    this.url = environment.baseUrl_zoomApi + ApiSearchDataServicePath.SearchByCityOrLetterPath;
    return  this.http.get<companySearchResult[]>(this.url, {params : {... query}})
               .pipe(
                   tap(response => {
                       console.log(JSON.stringify(response));
                   }),
                   catchError(this.handleError)
               ); 
      
  }
  keywordSuggested(term :Get_Search_Keywords_Query){
    this.url = environment.baseUrl_zoomApi + ApiSearchDataServicePath.SuggestKeywordsPath;

    return  this.http.get<search_sugestion_key_words[]>(this.url, {params : {... term}})
             .pipe(
                 tap(response => {
                     console.log(JSON.stringify(response));
                 }),
                 catchError(this.handleError)
             );
  }
  keywords_associated(kw_id : string,language_code : string){
    this.url = environment.baseUrl_zoomApi + ApiSearchDataServicePath.GetkeywordsAssociatedPath;
    var term = { keywords_id : kw_id,language_code : language_code};
    return  this.http.get<search_sugestion_key_words>(this.url, {params : {... term}})
             .pipe(
                 tap(response => {
                    // console.log(JSON.stringify(response));
                 }),
                 catchError(this.handleError)
             );
  }
  
  
  companyInfos(company :Get_Company_Details_Query){
    this.url = environment.baseUrl_zoomApi + ApiSearchDataServicePath.CompanyInfoPath;

    return  this.http.get<company>(this.url, {params : {... company}})
             .pipe(
                 tap(response => {
                     console.log(JSON.stringify(response));
                 }),
                 catchError(this.handleError)
             );
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        errorMessage = `An error occurred: ${err.error.message}`;
    } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}