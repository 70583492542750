import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-services/api-configuration';

import {ApiAuthDataService} from '../core/api-services/api-auth-and-user-data-service/api-auth-data-service'
//import { ApiAccountService } from './services/api-services/api-account-service/api-account.service'
//import { ApiUserService } from './services/api-services/api-user-service/api-user.service';
//import { LoggerService } from './services/util-services/logger.service';
import { ApiReferenceDataService } from '../core/api-services/api-reference-data-service/api-reference-data.service';
import { ApiSearchDataService } from '../core/api-services/api-search-data-service/api-search-data-service';


//import { ToastrModule } from 'ngx-toastr';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
 // imports: [ToastrModule.forRoot({positionClass:'toast-bottom-right', preventDuplicates:true})],
 imports :[],
  exports: [],
  declarations: [],
  providers: [
    ApiConfiguration,    
    ApiReferenceDataService,
    ApiSearchDataService,
    ApiAuthDataService,
  //  LoggerService
  ],
})
//https://www.bennadel.com/blog/3565-providing-module-configuration-using-forroot-and-ahead-of-time-compiling-in-angular-7-2-0.htm
//https://medium.com/@michelestieven/angular-writing-configurable-modules-69e6ea23ea42
export class CoreModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: ApiConfiguration,// this what is injected in the services using it
          useValue: params// this is what is passed as value to be injectted
        }
      ]
    }
  }

  constructor( 
              @Optional() @SkipSelf() parentModule: CoreModule,
              @Optional() http: HttpClient
            ) 
  {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}

