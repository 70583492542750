
export class ApiReferenceDataServicePath
{
    static readonly CreateRubrique = '/api/v1/ReferenceData/CreateRubrique';
    static readonly CreateSousRubrique = '/api/v1/ReferenceData/CreateSousRubrique';
    static readonly CreateCity = '/api/v1/ReferenceData/CreateCity';
    static readonly CreateQuater = '/api/v1/ReferenceData/CreateQuater';
    static readonly CreateStreet = '/api/v1/ReferenceData/CreateStreet';

    
    static readonly UpdateRubrique = '/api/v1/ReferenceData/UpdateRubrique';
    static readonly UpdateSousRubrique = '/api/v1/ReferenceData/UpdateSousRubrique';
    static readonly UpdateCity = '/api/v1/ReferenceData/UpdateCity';
    static readonly UpdateQuater = '/api/v1/ReferenceData/UpdateQuater';
    static readonly RunUpdateCitiesQuatersSearchTable = '/api/v1/ReferenceData/RunUpdateCitiesQuatersSearchTable';

    static readonly GetAllCitiesQuaters = '/api/v1/ReferenceData/GetAllCitiesQuaters';
    
    static readonly GetFormesJuridique = '/api/v1/ReferenceData/GetFormesJuridique';
    static readonly GetAllCountries = '/api/v1/ReferenceData/GetAllCountries';
    static readonly GetAllCities = '/api/v1/ReferenceData/GetAllCities';
    static readonly GetAllQuaters = '/api/v1/ReferenceData/GetAllQuaters';
    static readonly UpdateStreet = '/api/v1/ReferenceData/UpdateStreet';


    static readonly GetAllSectors = '/api/v1/ReferenceData/GetAllSectors';
    static readonly GetAllRubriques = '/api/v1/ReferenceData/GetAllRubriques';
    static readonly GetAllSousRubriques = '/api/v1/ReferenceData/GetAllSousRubriques';

    static readonly GetRubriquesForSector = '/api/v1/ReferenceData/GetRubriquesForSector';
    static readonly GetSousRubriqueForRubrique = '/api/v1/ReferenceData/GetSousRubriqueForRubrique';
    static readonly GetCitiesByCountry = '/api/v1/ReferenceData/GetCitiesByCountry';
    static readonly GetAllStreetsOrStreetByCity = '/api/v1/ReferenceData/GetAllStreetsOrStreetByCity';

    static readonly GetCompanyValidationStatus = '/api/v1/ReferenceData/GetCompanyValidationStatus';

    
}


