
export class user_logged
    {
        id_token: string;       
        access_token: string;
        refresh_token: string;
        expire_in: string;  
        
        user_name:string;
        first_name : string;
        role : string;
        permissions : string[];
        force_password_reset :boolean;
        first_login : boolean;
    }