<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Blog</span>
            <h2>Latest Blog <b>Post</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="Images"></a>
                    <div class="content">
                        <span>April 19, 2020 / <a href="#">Restaurant</a></span>
                        <h3><a routerLink="/blog-details">Denisto Centin Restaurant, Canada</a></h3>  
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="Images"></a>
                    <div class="content">
                        <span>June 29, 2020 / <a href="#">PLATFORM</a></span>
                        <h3><a routerLink="/blog-details">Top 10 Business Location in Usa</a></h3>  
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                 </div>
             </div>

             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="Images"></a>
                    <div class="content">
                        <span>July 15, 2020 / <a href="#">ADVICE</a></span>
                        <h3><a routerLink="/blog-details">Top 10 Visiting Place in Turkey</a></h3>  
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                 </div>
             </div>

             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog4.jpg" alt="Images"></a>
                    <div class="content">
                        <span>May 14, 2020 / <a href="#">CLUB</a></span>
                        <h3><a routerLink="/blog-details">Top 10 Fitness Club in Canada</a></h3>  
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                 </div>
             </div>

             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog5.jpg" alt="Images"></a>
                    <div class="content">
                        <span>March 14, 2020 / <a href="#">SHOPS</a></span>
                        <h3><a routerLink="/blog-details">The Best Coffee Shops In London</a></h3>  
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                 </div>
             </div>

             <div class="col-lg-4 col-md-6">
                 <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog6.jpg" alt="Images"></a>
                    <div class="content">
                        <span>January 22, 2020 / <a href="#">ADVICE</a></span>
                        <h3><a routerLink="/blog-details">Top 10 Visiting Place in England</a></h3>  
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                 </div>
             </div>

             <div class="col-lg-12 col-md-12">
                 <div class="pagination-area text-center">
                    <a routerLink="/blog" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog" class="page-numbers">2</a>
                    <a routerLink="/blog" class="page-numbers">3</a>
                    <a routerLink="/blog" class="page-numbers">4</a>
                    <a routerLink="/blog" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                 </div>
             </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>