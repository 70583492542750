import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common'; // //==> this is to be move to shared module
import { NgxSpinnerModule } from "ngx-spinner";  // //==> this is to be move to shared module
import {HttpClientModule} from '@angular/common/http'; // //==> this is to be move to shared module




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingInterceptor } from './core/helpers/loading-interceptors';
import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';
import { QuillModule } from 'ngx-quill'


import { FormsModule } from '@angular/forms'; //AutocompleteLibModule need  FormsModule

import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { CategoryComponent } from './components/pages/category/category.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { LoginRegisterComponent } from './components/pages/login-register/login-register.component';
import { TermsConditionComponent } from './components/pages/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ListingComponent } from './components/pages/listing/listing.component';
import { ListingDetailsComponent } from './components/pages/listing-details/listing-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';

import {InputTextModule} from 'primeng/inputtext'; // shared module
import {MenuItem} from 'primeng/api'; // shared module
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ComingSoonComponent } from './modules/public-pages/coming-soon/coming-soon.component';
import { TokenInterceptorTemporaire } from './core/helpers/token-interceptor-Temporaire';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    FooterStyleOneComponent,
    FooterStyleTwoComponent,
    AboutComponent,
    TeamComponent,
    PricingComponent,
    CategoryComponent,
    FaqComponent,
    TestimonialsComponent,
    ErrorComponent,
    LoginRegisterComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
    ComingSoonComponent,
    ShopComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    ListingComponent,
    ListingDetailsComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, // <-- required to use [(ngModule)] in HTML component
    InputTextModule,//==> this is to be move to shared module
    CommonModule, //==> this too is to be move to shared module
    NgxSpinnerModule, //==> this too is to be move to shared module
    HttpClientModule,//==> this too is to be move to shared module
   
    CoreModule.forRoot({rootUrl:environment.baseUrl_zoomUsersPermissions}), // but finally im passing base url in services
    QuillModule.forRoot(),
    AppRoutingModule
    
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorTemporaire, multi: true},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
